<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRoot } from '~/store'
import { LordIconSource, LordIconTrigger } from '~/types/assets'
import marqueeList, { duration as marqueeDuration } from '~/config/marquee'
import { useLocaleThemePath } from '~/composables/locale'
import type { LordIcon } from '~/types/assets'

const { theme } = storeToRefs(useRoot())

const { t } = useI18n()

const iconLove: LordIcon = {
  src: LordIconSource.heart,
  trigger: LordIconTrigger.loop,
  revert: false,
  delay: 4000,
  size: 35,
  minimal: false,
}

const calendlyRoot: Ref<HTMLElement | undefined> = ref()
const calendlyOptions = computed(() => ({
  url: 'https://calendly.com/lorenzo-rottigni/30min',
  text: t('layout.calendly.text-content'),
  color: '#171717',
  textColor: '#FFFFFF',
  branding: true,
}))
</script>

<template lang="pug">
footer
  section.footer__sign
    CalendlyPopupWidget(
      v-bind="calendlyOptions"
      :root-element="calendlyRoot"
    )
    .calendly.h-48(ref="calendlyRoot")
    .footer__sign__bmc-button
      a(
        href='https://www.buymeacoffee.com/hiimlorenzo',
        target="_blank"
        rel="nofollow"
        :title="$t('layout.anchors.buy-me-a-coffee.title')"
        :aria-label="$t('layout.anchors.buy-me-a-coffee.aria-label')"
      )
        NuxtImg(
          src='/button-api/?text=Buy%20me%20a%20coffee&emoji=&slug=hiimlorenzo&button_colour=171717&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00'
          alt="buy me a coffee widget"
          provider="bmc"
          :quality="50"
          :title="$t('layout.bmc.image.title')"
          :aria-label="$t('layout.bmc.image.aria-label')"
          :longdesc="$t('layout.bmc.image.longdesc')"
          decoding="async"
          width="271"
          height="40"
        )
    .footer__sign__marquee
      Vue3Marquee(
        direction='right'
        :duration='marqueeDuration'
        :pause-on-click='true'
        :pause-on-hover='true'
      )
        span.footer__sign__marquee__item(
          v-for='(marqueeItem, index) in marqueeList'
          :key='index'
          :class='{ word: true, odd: index % 2 === 0, even: index % 2 === 1 }'
        ) {{ marqueeItem }}
    .footer__sign__main
      p
        | {{ $t('footer.text-content[0]') }}
        IconLord(v-bind="iconLove")
        | {{ $t('footer.text-content[1]') }}
  section.footer__socials
    LayoutFooterSocials
  NuxtLink.logo(
    :to="useLocaleThemePath('/')"
    :title="$t('layout.anchors.home.title')"
    :aria-label="$t('layout.anchors.home.title')"
  )
    IconLogo(
      :size="125"
      :line-drawing="true"
    )
</template>

<style lang="sass">
footer
  @apply hidden lg:block
  section.footer__sign
    @apply w-full max-w-[40%] xl:w-[35%] xl:max-w-[520px] pr-12 fixed bottom-1 left-1 z-40
    .calendly-badge-widget
      @apply w-full max-w-[275px] left-3 bottom-[145px] cursor-pointer
    [classname="calendly-badge-content"]
      @apply w-full py-1 border-2 border-secondary border-dark-900 rounded-xl overflow-hidden flex-center flex-col text-center text-xs
      span
        @apply font-bold text-sm underline
    .footer__sign__bmc-button
      @apply w-full max-w-[275px] ml-2 border-2 border-secondary rounded-xl overflow-hidden bg-dark-900 mb-2
      a
        @apply w-full
        img
          @apply w-full max-h-[40px]
    .footer__sign__marquee
      @apply w-full py-1 bg-primary-o-75 border-t-2 border-primary
      span.footer__sign__marquee__item
        @apply text-xs xl:text-sm text-dark-900 mx-1 font-bold
    .footer__sign__main
      @apply flex justify-center items-center bg-light-300 dark:bg-dynamic-dark border-t-2 border-r-2 border-primary py-2
      clip-path: polygon(0 0, 100% 0, 94% 100%, 0% 100%)
      p
        @apply flex items-center text-primary text-center text-lg font-bold whitespace-nowrap
  section.footer__socials
    @apply w-fit pl-12 bottom-1 right-1 z-40 bg-light-300 dark:bg-dark-800 border-t-4 border-primary p-0 fixed
    clip-path: polygon(0 0, 100% 0, 100% 100%, 7% 100%)
  .logo
    @apply fixed bottom-14 right-4
</style>
